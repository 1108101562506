{
  "navbar": {
    "home": "Home",
    "photos": "Pictures",
    "contact": "Contact"
  },
  "header": {
    "fr-flag-alt": "French flag",
    "fr-flag-disabled-alt": "French flag disabled",
    "fr-flag-title": "Passer le site en français",
    "en-flag-alt": "English flag",
    "en-flag-disabled-alt": "English flag disabled",
    "en-flag-title": "Passer le site en anglais"
  },
  "footer": {
    "made-by": "Made by ",
    "and": "and",
    "legal-mentions": "Legal mentions"
  },
  "modal-refresh": {
    "title": "New version",
    "details": "A new version is available",
    "question": "Would you like to reload page now ?",
    "close": "Close",
    "refresh": "Reload"
  },
  "pictures": {
    "all": "All pictures",
    "types": {
      "home": "Home",
      "garden": "Garden",
      "surrondings": "Surrondings"
    }
  },
  "contact": {
    "title": "Information request",
    "form": "Contact form",
    "form-name": "Name",
    "form-name-placeholder": "Enter your name",
    "form-email": "Email",
    "form-email-placeholder": "Enter your email address",
    "form-message": "Message",
    "form-message-placeholder": "Enter your message",
    "form-send": "Send",
    "form-required": "This field is required",
    "form-incorrect-mail": "Given email address is incorrect",
    "message-sent": "Your message has been sent."
  },
  "construction": "This page is currently under construction"
}